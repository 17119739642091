import { IPayable, IPayableComment, IPaymentOrder, IPayableImport, IPayableDuplicate, IPaymentOrderExport } from './../models/payable.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayableService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  //-----------------------Payable-----------------------
  get(payable: IPayable): Observable<Array<IPayable>> {
    return this.httpClient.get<Array<IPayable>>(`${this.apiURL}/api/payable?filter=${JSON.stringify(payable)}`, this.httpOptions);
  };

  getGeneratePO(payable: IPayable): Observable<Array<IPayable>> {
    return this.httpClient.get<Array<IPayable>>(`${this.apiURL}/api/payable/generate/po?filter=${JSON.stringify(payable)}`, this.httpOptions);
  };

  post(payable: IPayable): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable?filter=${JSON.stringify(payable)}`, null, this.httpOptions);
  }

  postAll(payables: Array<IPayableImport>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/all`, payables, this.httpOptions);
  }

  put(payable: IPayable): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/payable?filter=${JSON.stringify(payable)}`, null, this.httpOptions);
  }

  delete(payable: Array<IPayable>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/payable/delete`, payable, this.httpOptions);
  }

  linkFileInfo(payable: IPayable): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/payable/link-file-info`, payable, this.httpOptions);
  }

  changeStatus(payables: Array<IPayable>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/payable/status`, payables, this.httpOptions);
  }

  discount(payables: Array<IPayable>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/discount`, payables, this.httpOptions);
  }

  chargeCode(payable: IPayable): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/charge-code?filter=${JSON.stringify(payable)}`, null, this.httpOptions);
  }

  getPayableComment(payableComment: IPayableComment): Observable<Array<IPayableComment>> {
    return this.httpClient.get<Array<IPayableComment>>(`${this.apiURL}/api/payable/comment?filter=${JSON.stringify(payableComment)}`, this.httpOptions);
  };

  checkDuplicate(payable: IPayable): Observable<Array<IPayableDuplicate>> {
    return this.httpClient.post<Array<IPayableDuplicate>>(`${this.apiURL}/api/payable/duplicate`, payable, this.httpOptions);
  };

  //-----------------------Payment Order-----------------------
  getPaymentOrder(po: IPaymentOrder): Observable<Array<IPaymentOrder>> {
    return this.httpClient.get<Array<IPaymentOrder>>(`${this.apiURL}/api/payable/po?filter=${JSON.stringify(po)}`, this.httpOptions);
  };

  paymentOrderInvoices(po: IPaymentOrder): Observable<Array<IPayable>> {
    return this.httpClient.get<Array<IPayable>>(`${this.apiURL}/api/payable/po/invoices?filter=${JSON.stringify(po)}`, this.httpOptions);
  };

  postPaymentOrder(po: IPaymentOrder): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/po?filter=${JSON.stringify(po)}`, null, this.httpOptions);
  }

  insertSat(po: IPaymentOrder): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/po/sat?filter=${JSON.stringify(po)}`, null, this.httpOptions);
  }

  generateExtra(po: IPaymentOrder): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/payable/po/extra?filter=${JSON.stringify(po)}`, null, this.httpOptions);
  }

  exportPo(po: IPaymentOrder): Observable<Array<IPaymentOrderExport>> {
    return this.httpClient.get<Array<IPaymentOrderExport>>(`${this.apiURL}/api/payable/po/export?filter=${JSON.stringify(po)}`, this.httpOptions);
  }

  deletePo(po: Array<IPaymentOrder>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/payable/po/delete`, po, this.httpOptions);
  }

}
