import { TimelineService } from "src/app/services/timeline.service";
import { DeleteDialogComponent } from "./payables-dialogs/delete-dialog/delete-dialog.component";
import { CustomerService } from "src/app/services/customer.service";
import { ParameterService } from "src/app/services/parameter.service";
import { ICustomer } from "./../../models/customer.model";
import { CommentService } from "./../../services/comment.service";
import { IPayable } from "./../../models/payable.model";
import { SelectionModel } from "@angular/cdk/collections";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Title } from "@angular/platform-browser";
import {
  ChangeStatusDialogComponent,
  IChangeStatusDialog,
} from "./payables-dialogs/change-status-dialog/change-status-dialog.component";
import { FilterDialogComponent } from "./payables-dialogs/filter-dialog/filter-dialog.component";
import { ImportPayableDialogComponent } from "./payables-dialogs/import-payable-dialog/import-payable-dialog.component";
import { ImportPdfDialogComponent } from "./payables-dialogs/import-pdf-dialog/import-pdf-dialog.component";
import { HotToastService } from "@ngneat/hot-toast";
import { XlsxExporterService } from "mat-table-exporter";
import { FilterService } from "src/app/services/filter.service";
import { PayableService } from "src/app/services/payable.service";
import { MatTableFilter } from "mat-table-filter";
import { IComment, ICommentRef } from "src/app/models/comment.model";
import { IAttachment, IAttachmentRef } from "src/app/models/attachment.model";
import { AwsService } from "src/app/services/aws.service";
import { AttachmentService } from "src/app/services/attachment.service";
import { ICarrier } from "src/app/models/parameter.model";
import { formatDate } from "@angular/common";
import moment from "moment";
import { AuthService } from "src/app/services/auth.service";
import { FormControl } from "@angular/forms";
import { ITimeline } from "src/app/models/timeline.model";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
} from "rxjs/operators";
import { forkJoin, of } from "rxjs";

@Component({
  selector: "dhl-payables",
  templateUrl: "./payables.component.html",
  styleUrls: ["./payables.component.scss"],
})
export class PayablesComponent implements OnInit {
  pageKey: string = "payables";
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IPayable>(
    this.allowMultiSelect,
    this.initialSelection
  );

  globalFilter: FormControl = new FormControl(
    this.filterService.getSearch(this.pageKey) || ""
  );

  filterEntity: IPayable = {
    PRC_SER: null,
    PRC_INV_SITUATION: null,
    PB_INV_NUM: null,
    PB_CTN: null,
    PB_CTN_TYPE: null,
    PRC_HBL: null,
    PB_MBL: null,
    PRC_CUS: null,
    PB_CAR: null,
    PB_PRC_ST_LIST: null,
    PB_PRC_SUB_ST_LIST: null,
    PB_VER_ST_LIST: null,
    PB_INC_START_DT: null,
    PB_INC_FINAL_DT: null,
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  payableTableDataSource = new MatTableDataSource<IPayable>();
  payableExportDataSource = new MatTableDataSource<IPayable>();
  filteredPayableTableDataSource = new MatTableDataSource<IPayable>();
  payableTableDisplayedColumns: Array<string> = [
    "select",
    "PRC_SER",
    "PRC_HBL",
    "SHIP_DPT_CODE",
    "PRC_CUS",
    "PRC_SHIP",
    "PRC_CTN",
    "PRC_CTN_TYPE",
    "PRC_INV_FRT",
    "PRC_INV_CH_DAYS",
    "PRC_INV_VAL",
    "PRC_INV_SITUATION",
    "PB_CAR",
    "PB_INV_NUM",
    "PB_MBL",
    "PB_BK",
    "PB_CTN",
    "PB_CTN_TYPE",
    "PB_CONT",
    "PB_POL",
    "PB_POD",
    "PB_FRT",
    "PB_CH_DAYS",
    "PB_ST_DT",
    "PB_END_DT",
    "PB_VAL_USD",
    "PB_PRC_ST",
    "PB_PRC_SUB_ST",
    "PB_VER_ST",
    "PB_CMT_ATCH",
    "actions",
  ];

  payableStatus: Array<string> = [
    "New Process",
    "Under Analysis",
    "Dispute DHL",
    "Dispute Carrier",
    "Programed Payment",
    "Paid (Dispute)",
    "Paid",
    "Invoice Canceled",
  ];

  payableSubStatus: Array<string> = [
    "Under Internal Review",
    "FT & PD Incorrect",
    "Incorrect Invoice",
    "Partial Invoice",
    "Invoice Not Found",
    "Waiting Confirmation (Free-time)",
    "Waiting for Loss Approval",
    "Waiting to Process Billing",
    "Waiting Confirmation of Ship Transfer",
    "Ok to Pay",
    "Paid",
    "Old Payment",
    "Invoice Canceled",
    "Ship Delay",
    "Terminal Window Delay",
    "Waiting Payment Order",
    "Waiting Reimbursement",
    "Dispute",
    "Waiting Customer to Pay",
  ];

  payableVerificationStatus: Array<string> = [
    "Unable To Verify",
    "Verified",
    "Over Valued",
    "Under Valued",
  ];

  invoiceStatusMap: Array<string> = [
    "Open",
    "In Dispute",
    "Invoiced",
    "Paid",
    "Cancelled",
    "Under Analysis",
    "Release Billing",
    "Customer Notified",
  ];

  headerGroups: Array<string> = [
    "header-select-group",
    "header-first-group",
    "header-second-group",
    "header-third-group",
  ];

  groups = [
    { id:'0', name: 'File Information', isEnabled: true },
    { id:'1', name: 'DHL Invoice', isEnabled: true },
    { id:'2', name: 'Carrier Invoice', isEnabled: true },
  ];

  carrierList: Array<ICarrier> = [];

  @ViewChild(MatTable) payableTable: MatTable<IPayable>;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  payableId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  showFirstGroup = true;
  showSecondGroup = true;
  showThirdGroup = true;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private payableService: PayableService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private awsService: AwsService,
    private carrierService: ParameterService,
    private authService: AuthService,
    private filterService: FilterService,
    private timelineService: TimelineService
  ) {
    this.titleService.setTitle("DHL | Payables");
  }

  ngOnInit(): void {
    this.carrierService
      .getCarrier()
      .subscribe((carriers) => (this.carrierList = carriers));

    const filter = this.filterService.getFilter("payable");
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.filterService.setSearch(this.pageKey, value);
        this.getPayables(this.filterEntity);
      });

    this.getPayables(this.filterEntity);
  }

  getPayables(filter: IPayable): void {
    const ref =
      this.pageSize > 20
        ? this.toast.loading("Loading...", { autoClose: false })
        : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if (this.filteredPayableTableDataSource.data.length === 0)
      this.hideLoader = false;
    this.payableService
      .get({
        ...filter,
        SEARCH: search,
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder,
        PAGE_NUMBER: this.pageNumber,
        PAGE_SIZE: this.pageSize,
      })
      .subscribe((payables) => {
        this.hideLoader = true;
        this.payableTableDataSource.data = payables;
        this.filteredPayableTableDataSource.data = payables;
        this.length = payables[0]?.TOTAL_ROWS;
        this.changeDetectorRef.detectChanges();
        ref?.close();
      });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredPayableTableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filteredPayableTableDataSource.filteredData.forEach((row) =>
          this.selection.select(row)
        );
  }

  onPaginateChange(event) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPayables(this.filterEntity);
  }

  onSortChange(event) {
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getPayables(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity,
        carrierList: this.carrierList,
      },
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity = filters;
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter("payable", this.filterEntity);
      this.getPayables(this.filterEntity);
    });
  }

  openImportDialog(): void {
    const dialog = this.dialog.open(ImportPayableDialogComponent, {
      data: this.payableTableDataSource.data,
    });
    dialog.afterClosed().subscribe(() => {
      this.getPayables(this.filterEntity);
    });
  }

  openImportPdfDialog(): void {
    this.dialog.open(ImportPdfDialogComponent);
  }

  openChangeStatusDialog(data: IChangeStatusDialog): void {
    const dialog = this.dialog.open(ChangeStatusDialogComponent, { data });

    dialog
      .afterClosed()
      .subscribe(
        ([payables, comment, files]: [
          Array<IPayable>,
          string,
          Array<File>
        ]) => {
          if (payables.length === 0 || !comment) {
            return;
          }

          const attachmentRefs: Array<IAttachmentRef> = [];
          const commentRefs: Array<ICommentRef> = [];

          payables.forEach((payable) => {
            attachmentRefs.push({
              REG_ID: Number(payable.PB_ID),
            });

            commentRefs.push({
              REG_ID: Number(payable.PB_ID),
              COMM_REF_GROUP: "payable",
              COMM_REF_USER: this.authService.userId,
            });
          });

          const newComment: IComment = {
            COMM_MESSAGE: comment,
            COMM_USER: this.authService.userId,
            COMM_REF: commentRefs,
          };

          const ref = this.toast.loading("Loading...", { autoClose: false });

          if (files.length > 0) {
            const commentOperation =
              this.commentService.postComment(newComment);
            const uploadOperation = this.awsService
              .uploadFiles(files, "payable", attachmentRefs)
              .pipe(
                this.toast.observe({
                  loading: "Uploading files...",
                  success: () => "Files uploaded successfully.",
                  error: (e) => `Error uploading files: ${e}`,
                }),
                mergeMap((attachments) => {
                  if (attachments) {
                    return this.attachmentService.postAttachment(attachments);
                  } else {
                    return of(null);
                  }
                }),
                catchError((error) => {
                  this.toast.error(`Error uploading files: ${error}`);
                  return of(null);
                })
              );
            const statusChangeOperation =
              this.payableService.changeStatus(payables);

            forkJoin([
              commentOperation,
              uploadOperation,
              statusChangeOperation,
            ]).subscribe(
              ([commentResponse, _, statusResponse]) => {
                this.toast.success(`${commentResponse}`);
                this.toast.success(`${statusResponse}`);
                this.getPayables(this.filterEntity);
              },
              (error) => {
                this.toast.error(`Error: ${error}`);
              },
              () => {
                ref.close();
              }
            );
          } else {
            const commentOperation =
              this.commentService.postComment(newComment);
            const statusChangeOperation =
              this.payableService.changeStatus(payables);

            forkJoin([commentOperation, statusChangeOperation]).subscribe(
              ([commentResponse, statusResponse]) => {
                this.toast.success(`${commentResponse}`);
                this.toast.success(`${statusResponse}`);
                this.getPayables(this.filterEntity);
              },
              (error) => {
                this.toast.error(`Error: ${error}`);
              },
              () => {
                ref.close();
              }
            );
          }
        }
      );
  }

  openDeleteDialog(data: IPayable): void {
    const dialog = this.dialog.open(DeleteDialogComponent, { data });
    dialog.afterClosed().subscribe(([willDelete, payable]) => {
      if (!willDelete) return;

      let payables: Array<IPayable> = [];

      if (payable) {
        payables.push(payable);
      } else {
        this.selection.selected.forEach((payable) => {
          payables.push(payable);
        });
      }

      const ref = this.toast.loading("Deleting...", { autoClose: false });
      this.payableService.delete(payables).subscribe(
        (response) => {
          ref.close();
          this.toast.success(String(response));
          this.getPayables(this.filterEntity);
        },
        (error) => {
          this.toast.error(error.error.Message);
        }
      );
    });
  }

  isOverflow(element) {
    if (element) {
      return element.length > 20;
    }
  }

  willDelete(data: IPayable | Array<IPayable>): boolean {
    let willDelete: boolean = true;
    if (data instanceof Array) {
      data.map((item) => {
        if (
          (item.PB_PRC_ST === 4 && item.PB_PRC_SUB_ST === 9) ||
          (item.PB_PRC_ST === 6 && item.PB_PRC_SUB_ST === 10)
        ) {
          willDelete = false;
        }
      });
    } else {
      if (
        (data.PB_PRC_ST === 4 && data.PB_PRC_SUB_ST === 9) ||
        (data.PB_PRC_ST === 6 && data.PB_PRC_SUB_ST === 10)
      ) {
        willDelete = false;
      }
    }

    return willDelete;
  }

  willChangeStatus(data: IPayable | Array<IPayable>): boolean {
    let willChangeStatus: boolean = true;
    if (data instanceof Array) {
      data.map((item) => {
        if (item.PB_PRC_ST === 6 && item.PB_PRC_SUB_ST === 10) {
          willChangeStatus = false;
        }
      });
    } else {
      if (data.PB_PRC_ST === 6 && data.PB_PRC_SUB_ST === 10) {
        willChangeStatus = false;
      }
    }

    return willChangeStatus;
  }

  export(): void {
    const headers = {
      PRC_SER: "SERVICE",
      PRC_HBL: "HOUSE BILL",
      SHIP_DPT_CODE: "DEPT",
      PRC_CUS: "CUSTOMER",
      PRC_SHIP: "SHIPMENT",
      PRC_CTN: "CONTAINER",
      PRC_CTN_TYPE: "TYPE",
      PRC_INV_FRT: "FREETIME",
      PRC_INV_CH_DAYS: "CHARGE DAYS",
      PRC_INV_VAL: "TOTAL",
      PRC_INV_SITUATION_TEXT: "INVOICE STATUS",
      PB_CAR: "CARRIER",
      PB_INV_NUM: "INVOICE NUMBER",
      PB_EMIS_DT_TEXT: "EMISSION DATE",
      PB_EXP_DT_TEXT: "EXPIRATION DATE",
      PB_MBL: "MASTER BILL",
      PB_BK: "BOOKING",
      PB_CTN: "CONTAINER",
      PB_CTN_TYPE: "TYPE",
      PB_CONT: "CONTRACT",
      PB_POL: "ORIGIN PORT",
      PB_POD: "DESTINATION PORT",
      PB_TL_DAYS: "TOTAL DAYS",
      PB_FRT: "FREETIME",
      PB_CH_DAYS: "CHARGE DAYS",
      PB_ST_DT_TEXT: "START DATE",
      PB_END_DT_TEXT: "END DATE",
      PB_VAL_USD: "AMOUNT USD",
      PB_VER_ST_TEXT: "VERIFICATION STATUS",
      PB_PRC_ST_TEXT: "STATUS",
      PB_PRC_SUB_ST_TEXT: "SUB-STATUS",
      PB_CMT: "STATUS COMMENT",
      PB_SAT: "SAT",
      PB_PAY_DT: "PAYMENT DATE",
    };

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading("Exporting...", { autoClose: false });
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.payableService
        .get({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search,
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder,
        })
        .subscribe((dataExport) => {
          this.payableExportDataSource.data = dataExport;

          const data: Array<IPayable> = this.prepareDataToExport([
            ...this.payableExportDataSource.data,
          ]);
          dataToExport.push(...data);

          this.exporter.export(dataToExport, {
            fileName: `payables_${formatDate(
              new Date(),
              "dd-MM-yyyy_HH.mm.ss",
              "en-US"
            )}`,
            columnWidths: [],
          });

          ref.close();
        });
    } else {
      const data: Array<IPayable> = this.prepareDataToExport([
        ...this.selection.selected,
      ]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `payables_${formatDate(
          new Date(),
          "dd-MM-yyyy_HH.mm.ss",
          "en-US"
        )}`,
        columnWidths: [],
      });
    }
  }

  prepareDataToExport(data: Array<IPayable>): Array<IPayable> {
    const dataToExport: Array<IPayable> = [];

    const datesText = {
      PB_EMIS_DT_TEXT: "",
      PB_EXP_DT_TEXT: "",
      PB_ST_DT_TEXT: "",
      PB_END_DT_TEXT: "",
      PB_PRC_ST_TEXT: "",
      PB_PRC_SUB_ST_TEXT: "",
      PB_VER_ST_TEXT: "",
      PRC_INV_SITUATION_TEXT: "",
    };

    data.forEach((defaultRule) => {
      const newData = { ...defaultRule, ...datesText };

      newData.PB_EMIS_DT_TEXT =
        newData.PB_EMIS_DT && moment(newData.PB_EMIS_DT).format("DD/MM/YYYY");
      newData.PB_EXP_DT_TEXT =
        newData.PB_EXP_DT && moment(newData.PB_EXP_DT).format("DD/MM/YYYY");
      newData.PB_ST_DT_TEXT =
        newData.PB_ST_DT && moment(newData.PB_ST_DT).format("DD/MM/YYYY");
      newData.PB_END_DT_TEXT =
        newData.PB_END_DT && moment(newData.PB_END_DT).format("DD/MM/YYYY");
      newData.PB_PRC_ST_TEXT = this.payableStatus[newData.PB_PRC_ST];
      newData.PB_PRC_SUB_ST_TEXT = this.payableSubStatus[newData.PB_PRC_SUB_ST];
      newData.PB_VER_ST_TEXT =
        this.payableVerificationStatus[newData.PB_VER_ST];
      newData.PRC_INV_SITUATION_TEXT =
        this.invoiceStatusMap[newData.PRC_INV_SITUATION];

      delete newData.PB_ID;
      delete newData.PB_EMIS_DT;
      delete newData.PB_EXP_DT;
      delete newData.PB_ST_DT;
      delete newData.PB_END_DT;
      delete newData.PB_PRC_ID;
      delete newData.PB_PRC_ST;
      delete newData.PB_PRC_SUB_ST;
      delete newData.PB_VER_ST;
      delete newData.PB_USER;
      delete newData.PB_INC_DT;
      delete newData.PB_CMT_USER_NAME;
      delete newData.PB_CMT_INC_DT;
      delete newData.PB_CMT_ST;
      delete newData.PB_CMT_SUB_ST;
      delete newData.PB_CMT_ATCH;
      delete newData.PRC_INV_SITUATION;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(payableId: number): void {
    this.payableId = payableId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = "hidden";
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = "unset";

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService
      .getComments({ COMM_REF_GROUP: `payable`, REG_ID: this.payableId })
      .subscribe((comments) => (this.comments = comments));
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.payableId),
          COMM_REF_GROUP: `payable`,
          COMM_REF_USER: this.authService.userId,
        },
      ],
    };

    this.commentService.postComment(newComment).subscribe(
      (response) => {
        this.toast.success(String(response));
        this.getComments();
        this.getTimeline();
      },
      (error) => {
        this.toast.error(error.error.Message);
      }
    );
  }

  getAttachments(): void {
    this.attachmentService
      .getAttachments({ ATCH_REF_GROUP: "payable", REG_ID: this.payableId })
      .subscribe((attachments) => (this.attachments = attachments));
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, "payable", [{ REG_ID: this.payableId }])
      .pipe(
        this.toast.observe({
          loading: "Uploading files...",
          success: () => "Files uploaded successfully.",
          error: (e) => `Error uploading files: ${e}`,
        })
      )
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe(
          (response) => {
            this.toast.success(String(response));
            this.getAttachments();
            this.getTimeline();
          },
          (error) => {
            this.toast.error(error.error.Message);
          }
        );
      });
  }

  getTimeline(): void {
    this.timelineService
      .getTimeline({
        TIMELINE_GROUP: "payable",
        TIMELINE_REG_ID: this.payableId.toString(),
      })
      .subscribe((timeline) => {
        this.timeline = timeline.map((event: ITimeline) => {
          return {
            ...event,
            TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME),
          };
        });
      });
  }

  linkFileInfo(payable: IPayable) {
    const data: IPayable = {
      PB_ID: payable.PB_ID,
      PB_PRC_ID: payable.PB_PRC_ID,
      PB_CTN: payable.PB_CTN,
      PB_MBL: payable.PB_MBL,
      PB_USER: this.authService.userId,
    };

    this.payableService.linkFileInfo(data).subscribe(
      (response) => {
        this.toast.success(String(response));
        this.getPayables(this.filterEntity);
      },
      (error) => {
        this.toast.error(error.error.Message);
      }
    );
  }

  toggleGroupVisibility(): void {
    this.updateDisplayedColumns();
  }

  updateDisplayedColumns() {
    const firstGroupColumns = [
      "PRC_SER",
      "PRC_HBL",
      "SHIP_DPT_CODE",
      "PRC_CUS",
      "PRC_SHIP",
      "PRC_CTN",
      "PRC_CTN_TYPE",
    ];
    const secondGroupColumns = [
      "PRC_INV_FRT",
      "PRC_INV_CH_DAYS",
      "PRC_INV_VAL",
      "PRC_INV_SITUATION",
    ];
    const thirdGroupColumns = [
      "PB_CAR",
      "PB_INV_NUM",
      "PB_MBL",
      "PB_BK",
      "PB_CTN",
      "PB_CTN_TYPE",
      "PB_CONT",
      "PB_POL",
      "PB_POD",
      "PB_FRT",
      "PB_CH_DAYS",
      "PB_ST_DT",
      "PB_END_DT",
      "PB_VAL_USD",
      "PB_PRC_ST",
      "PB_PRC_SUB_ST",
      "PB_VER_ST",
      "PB_CMT_ATCH",
    ];

    this.headerGroups = ["header-select-group"];
    this.payableTableDisplayedColumns = ["select"];

    if (this.groups[0].isEnabled) {
      this.headerGroups.push('header-first-group');
      this.payableTableDisplayedColumns.push(...firstGroupColumns);
    }

    if (this.groups[1].isEnabled) {
      this.headerGroups.push('header-second-group');
      this.payableTableDisplayedColumns.push(...secondGroupColumns);
    }

    if (this.groups[2].isEnabled) {
      this.headerGroups.push('header-third-group');
      this.payableTableDisplayedColumns.push(...thirdGroupColumns);
    }

    this.payableTableDisplayedColumns.push("actions");
  }
}
